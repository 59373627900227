// tslint:disable:variable-name
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { HTTP_STATUS, SERVER_CODES } from 'src/app/_metronic/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};


const DEFAULT_RESPONSE: BaseResponse<undefined> = {
  status: 500,
  message: 'Unknown error',
  errorCode: SERVER_CODES.UNKNOWN_ERROR,
  data: undefined
};

export abstract class TableService<T extends BaseModel> {
  // Private fields
  _items$ = new BehaviorSubject<T[]>([]);
  _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  // Getters
  get items$() {
    return this._items$.asObservable();
  }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }

  get errorMessage$() {
    return this._errorMessage.asObservable();
  }

  get subscriptions() {
    return this._subscriptions;
  }

  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }

  get filter() {
    return this._tableState$.value.filter;
  }

  get sorting() {
    return this._tableState$.value.sorting;
  }

  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }

  get grouping() {
    return this._tableState$.value.grouping;
  }

  protected http: HttpClient;
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;

  constructor(http: HttpClient) {
    this.http = http;
  }

  // dft custom
  getQuery(tableState: ITableState): string {
    const paginator = tableState.paginator;
    const textSearch = tableState?.searchTerm;
    const sorting = tableState.sorting;
    const filter = tableState.filter;
    let query = `?pageSize=${paginator.pageSize}&page=${paginator.page - 1}&textSearch=${(textSearch || '').trim()}`;

    if (sorting) {
      query += `&sortProperty=${sorting.column}&sortOrder=${sorting.direction}`;
    }
    if (filter) {
      for (const key of Object.keys(filter)) {
        query += `&${key}=${filter[key]}`;
      }
    }
    return query;
  }

  createOrUpdate(item: any): Observable<BaseResponse<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<T>(this.API_URL, item).pipe(
      map(response => this.castResponseData(response)),
      catchError(this.handleError.bind(this)),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // end of dft custom

  // CREATE
  // server should return the object with ID
  create(item: any, specifyUrl = null): Observable<BaseResponse<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    let url = this.API_URL;

    if (specifyUrl) {
      url = specifyUrl;
    }

    return this.http.post<T>(url, item).pipe(
      map(response => this.castResponseData(response)),
      catchError(this.handleError.bind(this)),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    const url = `${this.API_URL}${this.getQuery(tableState)}`;
    this._errorMessage.next('');
    return this.http.get<TableResponseModel<T>>(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ data: [], totalElements: 0 });
      })
    );
  }

  getItemById(id: string): Observable<BaseResponse<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.get<T>(url).pipe(
      map(response => this.castResponseData(response)),
      catchError(this.handleError.bind(this)),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: any, specifyUrl = null): Observable<BaseResponse<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    let url = `${this.API_URL}/${item.id}`;

    if (specifyUrl) {
      url = `${specifyUrl}/${item.id}`;
    }

    return this.http.put<T>(url, item).pipe(
      map(response => this.castResponseData(response)),
      catchError(this.handleError.bind(this)),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: string[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/updateStatus';
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: string): Observable<BaseResponse<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url).pipe(
      map(response => this.castResponseData(response as any)),
      catchError(this.handleError.bind(this)),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: string[] = []): Observable<BaseResponse<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/deleteItems';
    const body = { ids };
    return this.http.put(url, body).pipe(
      catchError(this.handleError.bind(this)),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch(isPatchStateWithoutFetch = true) {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.data);
          if (isPatchStateWithoutFetch) {
            this.patchStateWithoutFetch({
              paginator: this._tableState$.value.paginator.recalculatePaginator(
                res.totalElements
              ),
            });
          }
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          if (isPatchStateWithoutFetch) {
            this.patchStateWithoutFetch({
              grouping: this._tableState$.value.grouping.clearRows(itemIds),
            });
          }
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }

  protected castResponseData(data: T, baseResponse?: BaseResponse<undefined>): BaseResponse<T> {
    return {
      status: baseResponse?.status || HTTP_STATUS.OK,
      message: baseResponse?.message || 'success',
      errorCode: baseResponse?.errorCode || SERVER_CODES.SUCCESS,
      data,
    };
  }

  protected handleError(error: any): Observable<BaseResponse<T>> {
    console.error('ERROR request:', error);

    const responseError: BaseResponse<undefined> = _.cloneDeep(DEFAULT_RESPONSE);

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      responseError.message = `Error: ${error.error.message}`;
    }

    if (error instanceof HttpErrorResponse) {
      // Server-side errors
      responseError.status = error.status;
      responseError.message = error?.error?.message || error.statusText;
      responseError.errorCode = error?.error?.errorCode || SERVER_CODES.UNKNOWN_ERROR;
    }

    return of(this.castResponseData(undefined, responseError));
  }
}
