export const DynamicAsideMenuConfig = {
  items: [
    // {
    //   title: 'Dashboard',
    //   root: true,
    //   icon: 'flaticon2-architecture-and-city',
    //   svg: './assets/media/svg/icons/Design/Layers.svg',
    //   page: '/dashboard',
    //   translate: 'MENU.DASHBOARD',
    //   bullet: 'dot',
    // },
    {
      title: 'Quản trị hệ thống',
      root: true,
      bullet: 'dot',
      icon: 'flaticon2-user-outline-symbol',
      svg: './assets/media/svg/icons/General/User.svg',
      page: 'system-management',
      permission: ['QLND_ALL', 'QLND_VIEW', 'QLNDMH_ALL', 'QLNDMH_VIEW', 'QLNND_ALL', 'QLNND_VIEW', 'QLCH_VIEW', 'LSND_ALL', 'LSND_VIEW'],
      submenu: [
        {
          title: 'Quản lý Người dùng',
          page: '/system-management/users',
          permission: ['QLND_ALL', 'QLND_VIEW'],
        },
        {
          title: 'Quản lý Người dùng mHome',
          page: '/system-management/user-mhome',
          permission: ['QLNDMH_ALL', 'QLNDMH_VIEW'],
        },
        {
          title: 'Quản lý Nhóm người dùng',
          page: '/system-management/user-group',
          permission: ['QLNND_ALL', 'QLNND_VIEW'],
        },
        {
          title: 'Quản lý cấu hình',
          page: '/system-management/firmware-setting',
          permission: ['QLCH_ALL', 'QLCH_VIEW'],
        },
        {
          title: 'Lịch sử truy cập',
          page: '/system-management/access-history',
          permission: ['LSND_ALL', 'LSND_VIEW'],
        }
      ]
    },
    {
      title: 'Quản trị thông tin',
      root: true,
      bullet: 'dot',
      icon: 'flaticon2-user-outline-symbol',
      svg: './assets/media/svg/icons/Files/File.svg',
      page: 'info-management',
      permission: ['QLNDV_ALL', 'QLNDV_VIEW', 'QLKH_ALL', 'QLKH_VIEW', 'QLB_ALL', 'QLB_VIEW'],
      submenu: [
        {
          title: 'Quản lý Khách hàng',
          page: '/info-management/customers',
          permission: ['QLKH_ALL', 'QLKH_VIEW'],
        },
        {
          title: 'Quản lý Nhóm dịch vụ',
          page: '/info-management/service-group',
          permission: ['QLNDV_ALL', 'QLNDV_VIEW'],
        },
        {
          title: 'Quản lý Box',
          page: '/info-management/manage-box',
          permission: ['QLB_ALL', 'QLB_VIEW'],
        },
        {
          title: 'Quản lý camera',
          page: '/info-management/manage-camera-admin',
          permission: ['QLCMR_ALL', 'QLCMR_VIEW'],
        }
      ]
    },
    {
      title: 'Báo cáo',
      root: true,
      bullet: 'dot',
      icon: 'flaticon2-user-outline-symbol',
      svg: './assets/media/svg/icons/Home/Clock.svg',
      page: 'report',
      permission: ['BCKNB_ALL', 'BCKNB_VIEW', 'BCKNC_ALL', 'BCKNC_VIEW', 'BCTTND_ALL', 'BCTTND_VIEW'],
      submenu: [
        {
          title: 'Báo cáo kết nối Media Box',
          page: '/report/reportConnectMediaBox',
          permission: ['BCKNB_ALL', 'BCKNB_VIEW'],
        },
        {
          title: 'Báo cáo kết nối Camera',
          page: '/report/reportConnectCamera',
          permission: ['BCKNC_ALL', 'BCKNC_VIEW'],
        },
        {
          title: 'Báo cáo lịch sử người dùng trên camera',
          page: '/report/reportUserCameraHistory',
          permission: ['BCTTND_ALL', 'BCTTND_VIEW'],
        },
        {
          title: 'Báo cáo kinh doanh thiết bị Camera',
          page: '/report/reportRevenueCamera',
          permission: ['BCKDTBC_ALL', 'BCKDTBC_VIEW'],
        },
        {
          title: 'Báo cáo kinh doanh Cloud Camera',
          page: '/report/reportRevenueCloudCamera',
          permission: ['BCKDGCC_ALL', 'BCKDGCC_VIEW'],
        }
      ]
    },
    // {
    //   title: 'Quản trị Demo',
    //   bullet: 'dot',
    //   icon: 'flaticon2-list-2',
    //   svg: './assets/media/svg/icons/Shopping/Cart3.svg',
    //   root: true,
    //   page: '/quantri',
    //   submenu: [
    //     {
    //       title: 'example',
    //       page: '/quantri/example-crud'
    //     },
    //     // {
    //     //   title: 'Quản trị Box',
    //     //   page: '/quantri/manage-box'
    //     // },
    //   ]
    // }
  ]
};
