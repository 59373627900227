import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError, filter, take } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor<T, R> implements HttpInterceptor {

  private requestsSending: HttpRequest<T>[] = [];
  private refreshTokenInProgress = false;

  constructor(
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<R>> {
    this.requestsSending.push(request);
    this.authService.isLoadingSubject.next(true);
    this.refreshTokenInProgress = false;

    return next.handle(this.addTokenToRequest(request))
      .pipe(
        mergeMap(httpEvent => {
          if (httpEvent instanceof HttpResponse) {
            this.removeRequestSending(request);
          }

          return of(httpEvent);
        }),

        catchError(httpError => {
          // throw if call login api
          if (request.url.includes('auth/login') || !(httpError instanceof HttpErrorResponse && httpError.status === 401)) {
            this.removeRequestSending(request);

            return throwError(httpError);
          }

          // logout if fresh token fail
          // if (!request.url.includes('auth/login') || (httpError instanceof HttpErrorResponse && httpError.status === 401)) {
          if (request.url.includes('auth/token')) {
            this.removeRequestSending(request);

            this.authService.logout();

            return throwError(httpError);
          }

          // wait new token and call current api with new token
          if (this.refreshTokenInProgress) {
            return this.authService.currentTokenSubject.pipe(
              filter(newToken => newToken !== null),
              take(1),
              mergeMap(() => next.handle(this.addTokenToRequest(request)))
            );
          }

          this.removeRequestSending(request);

          return this.renewToken(request, next);
        })
      );
  }

  addTokenToRequest(request: HttpRequest<T>): HttpRequest<T> {
    const noTokenRequests = [
      'auth/login',
      'noauth/recoverPasswordByEmail',
      'noauth/resetPassword',
      'auth/token',
    ];

    if (noTokenRequests.some(uri => request.url.includes(uri))) {
      return request;
    }

    const token = this.authService.currentTokenValue;

    return request.clone({
      setHeaders: {
        Accept: 'application/json',
        authorization: `Bearer ${token}`
      }
    });
  }

  removeRequestSending(request: HttpRequest<T>): void {
    this.requestsSending = this.requestsSending.filter(re => re !== request);

    this.authService.isLoadingSubject.next(this.requestsSending.length > 0);
  }

  renewToken(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<R>> {
    this.refreshTokenInProgress = true;

    return this.authService.refreshToken()
      .pipe(mergeMap(tokenResponse => {
        this.refreshTokenInProgress = false;
        if (tokenResponse) {
          return next.handle(this.addTokenToRequest(request));
        }
      }));
  }
}
