import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Observable } from 'rxjs';
import { UsersTable } from './fake-db/users.table';
import { UsersCrudTable } from './fake-db/users-crud.table';
import { CarsTable } from './fake-db/cars.table';
// ECommerce
import { UsersGroupTable } from './fake-db/user-group.table';
import { ServiceGroupTable } from '../modules/dft/service-group/_fake/service-groups.table';
import { CustomersTable } from './fake-db/customers.table';

@Injectable({
  providedIn: 'root',
})
export class FakeAPIService implements InMemoryDbService {
  constructor() { }

  /**
   * Create Fake DB and API
   */
  createDb(): {} | Observable<{}> {
    // tslint:disable-next-line:class-name
    const db = {
      // auth module
      users: UsersTable.users,

      // user CRUD table
      usersCrud: UsersCrudTable.users,

      // data-table
      cars: CarsTable.cars,

      groupUser: UsersGroupTable.groups,

      serviceGroups: ServiceGroupTable.serviceGroups,

      customers: CustomersTable.customers
    };

    return db;
  }
}
