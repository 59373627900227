import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  jwtHelper = new JwtHelperService();

  constructor(private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentToken = this.authService.currentTokenValue;
    if (currentToken) {
      const currentUser = this.jwtHelper.decodeToken(currentToken);
      const isExpired = this.jwtHelper.isTokenExpired(currentToken);
      if (!isExpired && currentUser.scopes.indexOf('SYS_ADMIN') >= 0) {
        return true;
      }
      // logged in so return true
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
