import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import MD5 from 'crypto-js/md5';

import { UserModel } from '../_models/user.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SERVER_CODES } from 'src/app/_metronic/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  private tokenLocalStorageKey = `${environment.appVersion}-${environment.TOKEN_KEY}`;
  private refreshTokenLocalStorageKey = `${environment.appVersion}-${environment.REFRESH_TOKEN_KEY}`;

  // public fields
  isLoading$: Observable<boolean>;
  currentUserSubject = new BehaviorSubject<UserModel>(undefined);
  currentTokenSubject = new BehaviorSubject<string>(undefined);
  currentRefreshTokenSubject = new BehaviorSubject<string>(undefined);
  isLoadingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  get currentRefreshTokenValue(): string {
    return this.currentRefreshTokenSubject.value;
  }

  // public methods
  login(username: string, password: string): Observable<LoginResponse> {
    this.isLoadingSubject.next(true);

    return this.authHttpService.login({ username, password }).pipe(
      switchMap((response: LoginResponse) => {
        if (!response) {
          return throwError(response);
        }

        if (response) {
          this.setTokenFromLocalStorage(response?.token, response.refreshToken);
        }

        return of(response);
      }),

      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  get getDeviceId(): string {
    return MD5(this.deviceDetectorService.getDeviceInfo().userAgent).toString();
  }

  removeToken(): Observable<BaseResponse<undefined>> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.removeToken();
  }

  logout() {
    this.removeTokenFromLocalStorage();
    this.router.navigate(['/auth/login']);
  }

  getUserByToken(): Observable<User> {
    const token = this.getTokenFromLocalStorage();

    if (!token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(token).pipe(
      map(response => {
        const user = response;
        if (user) {
          this.setCurrentUser(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  createPassword(password: string, token: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .createPassword(password, token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePassword(oldPassword, newPassword)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  refreshToken() {
    this.isLoadingSubject.next(true);
    return this.authHttpService.refreshToken(this.currentRefreshTokenValue).pipe(
      map((response: LoginResponse) => {
        if (!response) {
          return throwError(response);
        }

        if (response) {
          this.setTokenFromLocalStorage(response?.token, response.refreshToken);
        }

        return response;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  hasAnyPermission(permission?: string | string[]): boolean {
    const userPermissions = this.currentUserValue?.permissionNames || [];

    if (!permission || !permission.length) {
      return true;
    }

    if (!userPermissions.length) {
      return false;
    }

    return (Array.isArray(permission) ? permission : [permission])
      .some(per => userPermissions.includes(per));
  }

  setTokenFromLocalStorage(token: string, refreshToken: string): boolean {
    // store auth token epiresIn in local storage to keep user logged in between page refreshes
    if (token) {
      localStorage.setItem(this.tokenLocalStorageKey, token);
      this.currentTokenSubject.next(token);

      localStorage.setItem(this.refreshTokenLocalStorageKey, refreshToken);
      this.currentRefreshTokenSubject.next(refreshToken);

      return true;
    }
    return false;
  }

  // private methods
  private getTokenFromLocalStorage(): string {
    const token = localStorage.getItem(this.tokenLocalStorageKey);
    this.currentTokenSubject.next(token);

    const refreshToken = localStorage.getItem(this.refreshTokenLocalStorageKey);
    this.currentRefreshTokenSubject.next(refreshToken);

    return token;
  }

  // private methods
  removeTokenFromLocalStorage() {
    localStorage.removeItem(this.tokenLocalStorageKey);
    this.currentTokenSubject.next(null);

    localStorage.removeItem(this.refreshTokenLocalStorageKey);
    this.currentRefreshTokenSubject.next(null);
  }

  private setCurrentUser(user: User): boolean {
    if (user) {
      const userModel = (new UserModel());
      userModel.setUser(user);
      this.currentUserSubject.next(userModel);

      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
