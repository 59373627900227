export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504
};

export const SERVER_CODES = {
  SUCCESS: 0,
  UNKNOWN_ERROR: 1,
  ROLENAME_ALREADY_EXIST: 17,
  INVALID_USER: 41,
  USER_BLOCKED: 81,
  INVALID_EMAIL: 11,
  PHONE_ALREADY_EXIST: 113,
  ROLE_ALREADY_USED: 888,
  FAIL_EMAIL: 32,
  ACTIVE_USER: 88,
  WRONG_PASSWORD: 31,
  INVALID_TOKEN: 31,
  SAME_PASSWORD: 41,
  EXIST_USER_GROUP_NAME: 49,
  EXIST_USER_EMAIL: 49,
  SERVICE_GROUP_NAME_EXISTED: 48,
  SERVICE_GROUP_DAY_EXISTED: 49,
  SERVICE_GROUP_NAME_AND_DAY_EXISTED: 50,
  CANNOT_DELETE_SERVICE_WAS_USED: 51,
  CANNOT_DELETE_USER_LOGGED: 52,
  CUSTOMER_PHONE_ALREADY_EXIST: 46,
  EXIST_USER_PHONE: 51,
  BLOCKED_USER: 42,
  CANNOT_DELETE_CUSTOMER_LOGGED: 51
};
