import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';

const API_USERS_URL = `${environment.apiUrl}/users`;
const API_LOGIN_URL = `${environment.apiUrl}/auth/login`;
const API_GET_LOGIN_USER_URL = `${environment.apiUrl}/auth/get-login-user`;
// const API_LOGOUT_URL = `${environment.apiUrl}/auth/logout`;
const API_LOGOUT_ADMIN_URL = `${environment.apiUrl}/auth/logout/webadmin`;
const API_CHANGE_PASSWORD_URL = `${environment.apiUrl}/auth/changePassword`;
const API_FORGOT_PASSWORD_URL = `${environment.apiUrl}/noauth/recoverPasswordByEmail`;
const API_RESET_PASSWORD_URL = `${environment.apiUrl}/noauth/resetPassword`;
const API_REFRESH_TOKEN_URL = `${environment.apiUrl}/auth/token`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {

  constructor(private http: HttpClient) { }

  // public methods
  login(request: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      API_LOGIN_URL,
      request
    );
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string) {
    return this.http.post<boolean>(API_FORGOT_PASSWORD_URL, {
      email
    });
  }

  // CREATE =>  POST: create a new password for user to the server
  createPassword(password: string, resetToken: string) {
    return this.http.post<boolean>(API_RESET_PASSWORD_URL, {
      password,
      resetToken
    });
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http.post<boolean>(`${API_CHANGE_PASSWORD_URL}`, {
      currentPassword,
      newPassword
    });
  }

  getUserByToken(token = null): Observable<GetLoginUserResponse> {
    return this.http.get<GetLoginUserResponse>(API_GET_LOGIN_USER_URL);
  }

  removeToken(): Observable<BaseResponse<undefined>> {
    return this.http.post<BaseResponse<undefined>>(
      API_LOGOUT_ADMIN_URL, {}
    );
  }

  refreshToken(refreshToken: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(API_REFRESH_TOKEN_URL, { refreshToken });
  }
}
