export class UsersCrudTable {
  public static users: any = [
    {
      id: '1',
      fullname: 'Sonni',
      email: 'sgabotti0@wsj.com',
      phone: '232323232321',
      office: 'GVN',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 0,
    },
    {
      id: '2',
      fullname: 'Lorem',
      email: 'lorem@wsj.com',
      phone: '232323232322',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 1,
    },
    {
      id: '3',
      fullname: 'Ipsum',
      email: 'ipsum@wsj.com',
      phone: '232323232323',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 1,
    },
    {
      id: '4',
      fullname: 'Dolor',
      email: 'dolor@wsj.com',
      phone: '232323232324',
      office: 'GVN',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 0,
    },
    {
      id: '5',
      fullname: 'Sit',
      email: 'sit@wsj.com',
      phone: '232323232325',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 1,
    },
    {
      id: '6',
      fullname: 'Amet',
      email: 'amet@wsj.com',
      phone: '232323232326',
      office: 'GVN',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 1,
    },
    {
      id: '7',
      fullname: 'Alpha',
      email: 'alpha@wsj.com',
      phone: '232323232327',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 1,
    },
    {
      id: '8',
      fullname: 'Beta',
      email: 'beta@wsj.com',
      phone: '232323232328',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 1,
    },
    {
      id: '9',
      fullname: 'Gama',
      email: 'gama@wsj.com',
      phone: '232323232329',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 0,
    },
    {
      id: '10',
      fullname: 'Omega',
      email: 'omega@wsj.com',
      phone: '232323232330',
      office: 'DFT',
      role: {
        id: 2,
        roleName: 'beta',
      },
      active: 1,
    },
    {
      id: '11',
      fullname: 'Mega',
      email: 'Mega@wsj.com',
      phone: '232323232331',
      office: 'GVN',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 1,
    },
    {
      id: '12',
      fullname: 'One',
      email: 'one@wsj.com',
      phone: '232323232332',
      office: 'DFT',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 0,
    },
    {
      fullname: 'Two',
      email: 'two@wsj.com',
      phone: '232323232333',
      office: 'DFT',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 1,
    },
    {
      id: '14',
      fullname: 'Three',
      email: 'three@wsj.com',
      phone: '232323232334',
      office: 'DFT',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 1,
    },
    {
      id: '15',
      fullname: 'Four',
      email: 'four@wsj.com',
      phone: '232323232335',
      office: 'GVN',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 1,
    },
    {
      id: '16',
      fullname: 'Five',
      email: 'five@wsj.com',
      phone: '232323232336',
      office: 'DFT',
      role: {
        id: 1,
        roleName: 'alpha',
      },
      active: 0,
    }
  ];
}
