export class ServiceGroupTable {
  public static serviceGroups: any = [
    {
      id: '1',
      name: 'Aliquip dolore',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 1,
      note: 'Esse culpa elit ipsum excepteur culpa sint consequat culpa qui cillum eiusmod do ut.',
      createdDate: '09/07/2016',
    },
    {
      id: '2',
      name: 'Magna mollit',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 1,
      note: 'Esse culpa elit ipsum excepteur culpa sint consequat culpa qui cillum eiusmod do ut.',
      createdDate: '09/07/2016',
    },
    {
      id: '3',
      name: 'Pariatur Lorem',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 0,
      note: 'Pariatur Lorem officia et irure laborum dolor.',
      createdDate: '09/07/2016',
    },
    {
      id: '4',
      name: 'Eu adipisicing',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 0,
      note: 'Eu adipisicing proident est consequat nostrud.',
      createdDate: '09/07/2016',
    },
    {
      id: '5',
      name: 'Id irure',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 1,
      note: 'Id irure ea officia pariatur anim reprehenderit cillum pariatur ut sint laboris Lorem culpa.',
      createdDate: '09/07/2016',
    },
    {
      id: '6',
      name: 'Excepteur excepteur',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 0,
      note: 'Excepteur excepteur dolor fugiat ea occaecat magna voluptate anim dolor.',
      createdDate: '09/07/2016',
    },
    {
      id: '7',
      name: 'Aliqua irure',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 1,
      note: 'Aliqua irure consectetur est quis magna duis aliqua Lorem tempor eu velit.',
      createdDate: '09/07/2016',
    },
    {
      id: '8',
      name: 'Sint irure',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 0,
      note: 'Sint irure elit ea deserunt deserunt magna sunt.',
      createdDate: '09/07/2016',
    },
    {
      id: '9',
      name: 'Do nisi',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 0,
      note: 'Do nisi tempor Lorem ut enim quis et enim aute.',
      createdDate: '09/07/2016',
    },
    {
      id: '10',
      name: 'Eiusmod anim',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 1,
      note: 'Eiusmod anim aliquip est nisi enim commodo deserunt incididunt Lorem sunt adipisicing nostrud occaecat.',
      createdDate: '09/07/2016',
    },
    {
      id: '10',
      name: 'Sit commodo',
      storageDays: 2,
      prices: [
        { name: 'p480', price: 20000 },
        { name: 'p720', price: 20000 },
        { name: 'p1080', price: 20000 },
        { name: 'p1440', price: 20000 },
        { name: 'p2160', price: 20000 },
      ],
      status: 0,
      note: 'Sit commodo fugiat culpa minim.',
      createdDate: '09/07/2016',
    },

  ];
}
