export class UsersTable {
  public static users: any = [
    {
      id: '1',
      username: 'admin',
      token: 'auth-token-8f3ae836da744329a6f93bf20594b5cc',
      name: 'Sean S',
      avatar: null,
      email: 'admin@demo.com',
      phone: '456669067890',
      note: null,
      active: true,
      office: 'SJC join stock',
      password: '12345678',
      roles: {
        id: '1',
        roleName: 'Supper Admin',
      }, // Administrator
      permissionName: [
        'dashboard',
        'bcsok'
      ]
    },
    {
      id: '2',
      username: 'user',
      token: 'auth-token-6829bba69dd3421d8762-991e9e806dbf',
      name: 'Megan F',
      avatar: null,
      email: 'user@demo.com',
      phone: '456669067891',
      note: null,
      active: false,
      office: 'SJC join stock 1',
      password: 'demo',
      roles: {
        id: '2',
        roleName: 'Mamager',
      }, // Manager
      permissionName: [
        'dashboard'
      ]
    },
    {
      id: '3',
      username: 'guest',
      token: 'auth-token-d2dff7b82f784de584b60964abbe45b9',
      name: 'Manu G',
      avatar: null,
      email: 'guest@demo.com',
      phone: '456669067892',
      note: null,
      active: true,
      office: 'SJC join stock 2',
      password: 'demo123456',
      roles: {
        id: '3',
        roleName: 'GUEST',
      }, // Guest
      permissionName: [
        'dashboard'
      ]
    },
  ];

  public static tokens: any = [
    {
      id: '1',
      token: 'auth-token-' + Math.random(),
    },
  ];
}
