import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth';
import { DynamicAsideMenuConfig } from '../../configs/dynamic-aside-menu.config';
import * as _ from 'lodash';

const emptyMenuConfig = {
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class DynamicAsideMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;
  constructor(
    private authService: AuthService
  ) {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localStorage
  // Default => from DynamicAsideMenuConfig
  private loadMenu() {
    const menu = _.cloneDeep(DynamicAsideMenuConfig);
    menu.items = menu.items.filter(item => {
      if (item?.submenu) {
        item.submenu = item.submenu.filter(subItem => {
          if (subItem?.submenu) {
            subItem.submenu = subItem.submenu.filter(sub2Item => {
              return this.authService.hasAnyPermission(sub2Item.permission || '');
            });
          }
          return this.authService.hasAnyPermission(subItem.permission || '');
        });
      }
      return this.authService.hasAnyPermission(item.permission || '');
    });

    this.setMenu(menu);
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
