export class UserModel implements User {

  createdTime: number;
  createdBy: string;
  updatedTime: number;
  updatedBy: string;
  id: string;
  tenantId: string;
  name: string;
  phone: string;
  email: string;
  office: string;
  avatar: string;
  active: boolean;
  type: string;
  tbUserId: string;
  searchText: string;
  roles: Role[];
  permissionNames: string[];
  password: string;

  setUser(user: any) {

    this.id = user.id;
    this.createdTime = user.createdTime || '';
    this.createdBy = user.createdBy || '';
    this.updatedTime = user.updatedTime || '';
    this.updatedBy = user.updatedBy || '';
    this.tenantId = user.tenantId || '';
    this.name = user.name || '';
    this.phone = user.phone || '';
    this.email = user.email || '';
    this.office = user.office || '';
    this.avatar = user.avatar || '';
    this.active = user.active || null;
    this.type = user.type || '';
    this.tbUserId = user.tbUserId || '';
    this.searchText = user.searchText || '';
    this.roles = user.roles || null;
    this.permissionNames = user.permissionNames || [];
    this.password = user.password || '';
  }

  get currentRole(): Role {
    return this.roles[0] || null;
  }
}
