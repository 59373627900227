export class CustomersTable {
  public static customers: any = [
    {
      id: '1',
      code: '00000001',
      token: 'auth-token-8f3ae836da744329a6f93bf20594b5cc',
      name: 'Nguyễn Bảo Trân',
      type: 'Cá nhân',
      avatar: null,
      email: 'tranbt@demo.com',
      phone: '0365987658',
      address: '24 Hoàng Quốc Việt, Nghĩa Đô, Cầu Giấy, Hà Nội.',
      boxs: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ],
      serviceGroup: {
        id: '1',
        name: 'Camera hộ gia đình',
        storageDays: '2020-01-15',
        prices: [],
        status: '1',
        note: '',
        createdDate: '2020-01-10',
      },
      totalPayment: '1500000',
      active: 1,
      createDate: '2020-01-01',
      note: 'Khách hàng yêu cầu thanh toán cuối tháng'
    },
    {
      id: '2',
      code: '00000002',
      token: 'auth-token-8f3ae836da744329a6f93bf20594b5cd',
      name: 'Đỗ Hoài Sa',
      type: 'Cá nhân',
      avatar: null,
      email: 'sadh@demo.com',
      phone: '0123698589',
      address: '01 Nguyễn Thị Định, Hà Nội',
      boxs: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ],
      serviceGroup: {
        id: '2',
        name: 'Camera hộ gia đình',
        storageDays: '2020-01-15',
        prices: [],
        status: '1',
        note: '',
        createdDate: '2020-01-10',
      },
      totalPayment: '1500000',
      active: 1,
      createDate: '2020-01-01',
      note: 'Khách hàng yêu cầu đường truyền cao'
    },
    {
      id: '3',
      code: '00000003',
      token: 'auth-token-8f3ae836da744329a6f93bf20594b5cf',
      name: 'SJC group company',
      type: 'Doanh nghiệp',
      avatar: null,
      email: 'sjcsupport@gmail.com',
      phone: '0698562456',
      address: '29 Trần Quốc Hoàn, Hà Nội',
      boxs: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ],
      serviceGroup: {
        id: '2',
        name: 'Camera chung cư',
        storageDays: '2020-01-15',
        prices: [],
        status: '1',
        note: '',
        createdDate: '2020-01-10',
      },
      totalPayment: '50000000',
      active: 1,
      createDate: '2020-01-01',
      note: 'Khách hàng thân thiết'
    },
  ];

  public static tokens: any = [
    {
      id: '1',
      token: 'auth-token-' + Math.random(),
    },
  ];
}
