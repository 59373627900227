import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Check is whitespace validator
 *
 * @param AbstractControl control
 * @return mixed
 */
export const whiteSpaceValidator = (control: AbstractControl) => {
  const isSpace =  control.value && control.value.trim() === '';
  return isSpace ? { whitespace: true } : null;
};

export const minMoneyLength = (length = 0): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && control.value.replace(/[^0-9]/g, '')?.length < length && +control.value.replace(/\./g, '') > 0
    ? { minmoneylength: true }
    : null;
};

export const moneyLengthNotIn1And3 = (): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && control.value.replace(/[^0-9]/g, '')?.length < 3
  && control.value.replace(/[^0-9]/g, '')?.length > 0 && +control.value.replace(/\./g, '') > 0
    ? { minmoneylength: true }
    : null;
};

export const maxMoneyLength = (length = 0): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && control.value.replace(/[^0-9]/g, '')?.length > length ?
    { maxmoneylength: true }
    : null;
};

export const maxValueMoney = (maxValue = 1000000000): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && +control.value.replace(/\./g, '') > maxValue ?
    { maxvaluemoney: true }
    : null;
};

export const minValueMoney = (minValue = 0): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && +control.value.replace(/\./g, '') <= minValue ?
    { minvaluemoney: true }
    : null;
};

export const minTrimLength = (length = 0): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && control.value.trim().length > 0 && control.value.trim().length < length ?
    { mintrimlength: true }
    : null;
};

export const maxTrimLength = (length = 0): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  return control.value && control.value.trim().length > length ?
    { maxtrimlength: true }
    : null;
};

export const phoneRegex = (control: AbstractControl): ValidationErrors => {
  const pattern = /^(\+|\d)\d*$/;

  return control.value && control.value.trim().length >= 6 && control.value.trim().length <= 20 && !pattern.test(control.value.trim()) ?
    { phoneregex: true }
    : null;
};

export const REGEX = {
  regexPassword: /^[a-zA-Z0-9!@#$%^&*(),./\|=+-~\s<>?:;"'\[\]{}]*$/,
  regexIpAddress: /^[0-9.]*$/,
  regexCode: /^[a-zA-Z0-9\@\#\$\%\^\&\*\/\\\|\=\+\-\~\_\`\<\>]*$/,
  regexNameVietnamese: /^[\?\.\,\!\:\;\(\)\"\'a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếềìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾỀưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵýỷỹ ]*$/,
  regexPersionalNameVietnamese: /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếềìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾỀưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵýỷỹ ]*$/,
  regexDate: /^[0-9/]+$/,
  regexPass: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,50}$/,
  regexPassUser: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).*$/,
};
