export class UsersGroupTable {
  public static groups: any = [
    {
      createdTime: 1639216495650,
      createdBy: '1c308fe4-575e-4f5b-b57f-11386bafd49b',
      updatedTime: 1639217173918,
      updatedBy: '3180d5ca-f6fc-4450-b597-3c8823dd48a0',
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      roleName: 'Vận hành kinh doanh',
      roleType: 'WEB_ADMIN',
      note: 'Nhóm người dùng vận hành kinh doanh',
      usersCount: 4,
      permissionEntities: [
        {
          id: '12cfee5f-1b78-4bd1-93eb-1d0e1a868dbd',
          permission: 'QLKH_CREATE'
        },
        {
          id: '12175104-a107-4d09-acad-5a79dbdaf478',
          permission: 'QLB_VIEW'
        }
      ]
    },
    {
      createdTime: 1639216495650,
      createdBy: '1c308fe4-575e-4f5b-b57f-11386bafd49b',
      updatedTime: 1639217173918,
      updatedBy: '3180d5ca-f6fc-4450-b597-3c8823dd48a0',
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
      roleName: 'Vận hành giao dịch',
      roleType: 'WEB_ADMIN',
      note: 'Nhóm người dùng vận hành giao dịch',
      usersCount: 12,
      permissionEntities: [
        {
          id: '12cfee5f-1b78-4bd1-93eb-1d0e1a868dbd',
          permission: 'QLKH_CREATE'
        },
        {
          id: '12175104-a107-4d09-acad-5a79dbdaf478',
          permission: 'QLB_VIEW'
        }
      ]
    }
  ];
}

